<template>
<div id="projects">
    <section id="inner-headline">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2 class="pageTitle">Services</h2>
                </div>
            </div>
        </div>
    </section>
    <section id="content">
        <div class="container">
			<Services :isHome="'NO'"></Services>
        </div>
    </section>
</div>
</template>

<script type="text/javascript" scoped>
import Services from "@/components/Services.vue"

export default{
	name: "ServicesView",
	components: {
		Services,
	},
}
</script>

<style type="text/css" scoped>
</style>